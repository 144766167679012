import React from 'react';
import { Col, Row } from 'antd';
import { PageTitle } from '../../components/common/PageTitle';
import { withLoading } from '../../hocs/withLoading.hoc';
// import TradingMiniChart from '../../components/charts/TradingMiniChart';
//import TradingStatisticsCards from '../../components/cards/TradingStatisticsCards';
import DivergingStackedBarChart from '../../components/charts/DivergingStackedBarChart';
import { OptionSummaryCard } from '../../components/cards/optionSummaryCard/OptionSummaryCard/OptionSummaryCard';
import { useAppSelector } from '../../hooks/reduxHooks';
import { useResponsive } from '../../hooks/useResponsive';


import * as S from './OpenInterestPage.styles';

const OpenInterestPage = () => {
  const { isTablet, isDesktop } = useResponsive();

  let expiryDateList = useAppSelector((state) => state.option.options.dates);
  const OptionSummaryCardWithLoading = withLoading(OptionSummaryCard);
  const DivergingStackedBarChartWithLoading = withLoading(DivergingStackedBarChart);
  // const TradingMiniChartWithLoading = withLoading(TradingMiniChart);
  // const TradingStatisticsCardsWithLoading = withLoading(TradingStatisticsCards);
  const desktopLayout = (
    <Row>
      <S.LeftSideCol xl={15} xxl={17}>
        <Row gutter={[30, 30]}>
          <Col span={24}>
            {/* <TradingStatisticsCardsWithLoading /> */}
          </Col>
          {expiryDateList.map((item, index) => {
            const [expiryDate, periodType] = item.split('_');
            return (
              <Col span={24} key={index}>
                <DivergingStackedBarChartWithLoading expiryDate={expiryDate} periodType={periodType} category='OpenInterest' />
              </Col>
            );
          })}
        </Row>
      </S.LeftSideCol>

      <S.RightSideCol xl={9} xxl={7}>
        <div id="blood-screening">
          {/* <TradingMiniChart /> */}
         
        </div>
        <S.Space />
        <S.ScrollWrapper id="patient-timeline">
          <OptionSummaryCardWithLoading />
        </S.ScrollWrapper>
      </S.RightSideCol>
    </Row>
  );

  return (
    <>
      <PageTitle>Open Interest</PageTitle>
      {desktopLayout}
    </>
  );
};

export default OpenInterestPage;




