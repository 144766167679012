import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { gql } from '@apollo/client';
import { getMaxValueArray, getMaxVal, getStrikeRange, openInterestArr, gexArr, dexArr } from '../../adaptor/data'
import { client } from '../../graphql/apolloClient';
import { formatMsg } from '../../utils/utils'

// const GET_SYMBOLS = gql`
//     query GetSymbol {
//       stocks {
//         id
//         symbol
//         iconName
//         description
//         category
//         country
//         exchangeSite
//         price {
//             last 
//         }
//       }
//     }
//   `;
const GET_SYMBOLS = gql`
    query GetSymbol {
      stocks {
        id
        symbol
        iconName
        description
        category
        country
        exchangeSite
      }
    }
  `;

const GET_OPTIONGROUPS_BY_SYMBOL = gql`
    query OptionGroup($optionGroupInput: OptionGroupInput!) {
      optionGroups(optionGroupInput: $optionGroupInput) {
        symbol
        strike
        expDate
        periodType
        prevCallOI
        prevPutOI
        prevPGex
        prevPDex
        prevNGex
        prevNDex
        currCallOI
        currPutOI
        currPGex
        currPDex
        currNGex
        currNDex
        currDate
    }
  }
`;
const GET_OPTIONSUMMARIES_BY_SYMBOL = gql`
    query OptionSummary($optionSummaryInput: OptionSummaryInput!) {
      optionSummaries(optionSummaryInput: $optionSummaryInput) {
        symbol
        expDate
        periodType
        dataDate
        pgoi
        ngoi
        callRes
        putSupp
        hoiLevel
    }
  }
`;

const initialState = {
    symbol: 'TSLA',
    symbols: {
        list: [],
        status: false,
        isLoading: '',
        message: '',
    },
    options: {
        status: false,
        dict: {},
        graphicParams: [],
        isLoading: '',
        message: '',
        dates: [],
    },
    optionSummaries: {
        list: [],
        status: false,
        isLoading: '',
        message: '',
    },
};

export const fetchSymbols = createAsyncThunk('option/fetchSymbols', async () => {
    const { data } = await client.query({
        query: GET_SYMBOLS,
    });
    return data.stocks;
});


export const fetchOptionBySymbol = createAsyncThunk('option/fetchOptionBySymbol', async (optionGroupInput) => {
    const { data } = await client.query({
        query: GET_OPTIONGROUPS_BY_SYMBOL,
        variables: { optionGroupInput }, // 传递股票符号作为参数
    });

    let newData = {};
    const currentSymbol = optionGroupInput.symbol;
    const optionGroupList = data.optionGroups;
    
    const dict = {};
    const params = [];

    optionGroupList.forEach((item) => {
        const { expDate,periodType, ...rest } = item;
        if (!dict[expDate + '_' + periodType]) {
            dict[expDate + '_' + periodType] = [];
        }
        dict[expDate + '_' + periodType].push(rest);
    });
    newData.symbol = currentSymbol;
    newData.dict = dict;
    newData.expiryDateList = Object.keys(dict).sort();

    // 把所有的和图表相关的参数放在这里
    for (const [key, value] of Object.entries(dict)) {
        const [expiryDate, periodType] = key.split('_');
        const param =
        {
            expiryDate: expiryDate,
            periodType: periodType,
            openInterestMax: getMaxVal(getMaxValueArray(value, openInterestArr)),
            gexMax: getMaxVal(getMaxValueArray(value, gexArr)),
            dexMax: getMaxVal(getMaxValueArray(value, dexArr)),
            openInterestStrikeRange: getStrikeRange(getMaxValueArray(value, openInterestArr)),
            gexStrikeRange: getStrikeRange(getMaxValueArray(value, gexArr)),
            dexStrikeRange: getStrikeRange(getMaxValueArray(value, dexArr)),
        }
        params.push(param)
    
    }

    newData.graphicParams = params;
    return newData;
});


export const fetchOptionSummaryBySymbol = createAsyncThunk('option/fetchOptionSummaryBySymbol', async (optionSummaryInput) => {

    const { data } = await client.query({
        query: GET_OPTIONSUMMARIES_BY_SYMBOL,
        variables: { optionSummaryInput }, // 传递股票符号作为参数
    });

    let newData = {};
    const optionSummaryList = data.optionSummaries;
    newData.optionSummaryList = optionSummaryList.slice().sort((a, b) => new Date(a.expDate) - new Date(b.expDate));
    return newData;
});


export const optionSlice = createSlice({
    name: 'option',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            //symbols reducer
            .addCase(fetchSymbols.pending, (state) => {
                state.symbols.isLoading = true;
                state.symbols.status = false;
                state.symbols.message = ''
            })
            .addCase(fetchSymbols.fulfilled, (state, action) => {
                state.symbols.list = action.payload;
                state.symbols.isLoading = false;
                state.symbols.message = '';
                state.symbols.status = true;
            })
            .addCase(fetchSymbols.rejected, (state, action) => {
                state.symbols.message = formatMsg(action.error.message);
                state.symbols.isLoading = false;
                state.symbols.status = false;
            })
            //get option by symbol reducer
            .addCase(fetchOptionBySymbol.pending, (state) => {
                state.options.isLoading = true;
                state.options.status = false;
            })
            .addCase(fetchOptionBySymbol.fulfilled, (state, action) => {
                state.symbol = action.payload.symbol;
                state.options.isLoading = false;
                state.options.message = '';
                state.options.dict = action.payload.dict
                state.options.graphicParams = action.payload.graphicParams
                state.options.dates = action.payload.expiryDateList
                state.options.status = true;
            })
            .addCase(fetchOptionBySymbol.rejected, (state, action) => {
                state.options.message = formatMsg(action.error.message);
                state.options.isLoading = false;
                state.options.status = false;
            })
            //get option summary by symbol reducer
            .addCase(fetchOptionSummaryBySymbol.pending, (state) => {
                state.optionSummaries.isLoading = true;
                state.optionSummaries.status = false;
            })
            .addCase(fetchOptionSummaryBySymbol.fulfilled, (state, action) => {
                state.optionSummaries.isLoading = false;
                state.optionSummaries.message = '';
                state.optionSummaries.list = action.payload.optionSummaryList;
                state.optionSummaries.status = true;
            })
            .addCase(fetchOptionSummaryBySymbol.rejected, (state, action) => {
                state.optionSummaries.message = formatMsg(action.error.message);
                state.optionSummaries.isLoading = false;
                state.optionSummaries.status = false;
            })
            ;
    },
});


export default optionSlice.reducer;